import {API_PATH, HTTP_OK} from '@app/common/constants';
import {incentiveActions} from '@app/screens/incentive-configuration/redux';
import {incentiveSelector} from '@app/screens/incentive-configuration/redux/selectors';
import NetworkService from '@app/services/network/NetworkService';
import {Button, Card, LinearProgress, Typography} from '@mui/material';
import {Box} from '@mui/system';
import axios, {CancelTokenSource} from 'axios';
import {useEffect, useRef, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';

export const UploadProgress = () => {
  const [progress, setProgress] = useState(0);
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const dispatch = useDispatch();
  const cancelToken = useRef<CancelTokenSource>();

  const salesTransferExcelUploadData = useSelector(
    incentiveSelector.getSalesTransferExcelUploadData(),
    shallowEqual,
  );

  const getErrorMessage = (res: any) => {
    let error = res?.data?.details[0]?.message ?? res?.data?.description ?? 'Excel File Upload Failed!';
    return error;
  }

  useEffect(() => {
    if (
      salesTransferExcelUploadData?.excelFile &&
      Object.keys(salesTransferExcelUploadData?.filters).length &&
      !salesTransferExcelUploadData.uploading
    ) {
      setShow(true);
      resetData();
      uploadFile();
    }
  }, [salesTransferExcelUploadData]);

  const uploadFile = async () => {
    dispatch(
      incentiveActions.setSalesTransferExcelUploadData({
        ...salesTransferExcelUploadData,
        uploading: true,
      }),
    );
    cancelToken.current = axios.CancelToken.source();
    const formData = new FormData();
    formData.append('Blob', salesTransferExcelUploadData?.excelFile);
    formData.append(
      'StaffPositionId',
      salesTransferExcelUploadData.filters.staffPositionId,
    );
    formData.append(
      'QuarterId',
      salesTransferExcelUploadData.filters.quarterId,
    );
    formData.append(
      'FromGroupPoolId',
      salesTransferExcelUploadData.filters.fromGroupPoolId,
    );
    formData.append(
      'ToGroupPoolId',
      salesTransferExcelUploadData.filters.toGroupPoolId,
    );
    formData.append(
      'toFlag',
      salesTransferExcelUploadData.filters.toFlag,
    );
    formData.append(
      'fromFlag',
      salesTransferExcelUploadData.filters.fromFlag,
    );

    NetworkService.postUploadFile(
      API_PATH.incentiveConfiguration.uploadSalesTransferDataExcel,
      formData,
      {},
      null,
      onUploadProgress,
      cancelToken.current.token,
    )
      .then(res => {
        if (res?.status === HTTP_OK) {
          setSuccess(true);
          dispatch(incentiveActions.setRefetchSalesTransfer(Math.random()));
        } else {
          setError(getErrorMessage(res));
        }
      })
      .catch(error => {
        setError(getErrorMessage(error));
      })
      .finally(() => {
        dispatch(
          incentiveActions.setSalesTransferExcelUploadData({
            excelFile: null,
            uploading: false,
            filters: {},
          }),
        );
      });
  };

  const onUploadProgress = (progress: any) => {
    const percentCompleted = Math.round(
      (progress.loaded * 100) / progress.total,
    );
    setProgress(percentCompleted);
  };

  const resetData = () => {
    setError('');
    setSuccess(false);
    setProgress(0);
    setError('');
  };

  const onCancel = () => {
    cancelToken.current?.cancel('Cancelled by user!');
    resetData();
    setShow(false);
  };

  const uploading = salesTransferExcelUploadData?.uploading;
  const progressAdjustment = progress > 2 ? 1 : 0;
  if (!show) return null;

  const buttonStyle: any = {
    fontFamily: ['Poppins'].join(','),
    fontSize: 12.7,
    letterSpacing: 0,
    textAlign: 'center',
    backgroundColor: '#322b7c',
    opacity: uploading ? 0.4 : 1,
    color: 'white',
    border: 0,
    borderRadius: 7,
    minWidth: 100,
    height: 40,
  };

  return (
    <Card
      sx={{
        p: 2.5,
        pt: 2,
        position: 'fixed',
        top: 10,
        right: 10,
        width: 350,
        height: 180,
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Box>
        <Typography
          gridTemplateAreas="title"
          fontSize={22}
          color={'primary.main'}>
          Excel upload
        </Typography>
        {error ? (
          <Typography gridTemplateAreas="title" fontSize={12} color={'red'}>
            {error}
          </Typography>
        ) : (
          <Typography
            gridTemplateAreas="title"
            fontSize={10}
            color={!success ? '#F2994A' : '#27AE60'}>
            {!success
              ? 'File upload in progress'
              : 'File uploaded successfully'}
          </Typography>
        )}
      </Box>
      {error ? null : (
        <Box>
          <CustomLinearProgress value={progress} max={100} success={success} />
          <Typography
            gridTemplateAreas="title"
            marginTop={'5px'}
            fontSize={11}
            fontWeight={'500'}
            color={'#7090B0'}>
            {`${
              success ? 100 : Math.round(progress - progressAdjustment)
            }% completed`}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}>
        {success ? (
          <Button
            disabled
            variant="contained"
            size="small"
            style={{...buttonStyle, marginRight: 15, opacity: 0.3}}>
            Cancel
          </Button>
        ) : (
          <Button
            variant="outlined"
            onClick={onCancel}
            size="small"
            style={{
              fontFamily: ['Poppins'].join(','),
              fontSize: 12.7,
              cursor: 'pointer',
              letterSpacing: 0,
              textAlign: 'center',
              border: 0,
              borderRadius: 7,
              marginRight: 15,
              minWidth: 100,
              height: 35,
            }}>
            Cancel
          </Button>
        )}
        <Button
          disabled={uploading}
          variant="contained"
          onClick={() => {
            setShow(false);
            resetData();
          }}
          size="small"
          style={buttonStyle}>
          Ok
        </Button>
      </Box>
    </Card>
  );
};

const CustomLinearProgress = ({
  value,
  max,
  success,
}: {
  value: number;
  max: number;
  success: boolean;
}) => {
  const percentage = (value / max) * 100;

  return (
    <Box position="relative" display="flex" alignItems="center" width="100%">
      <LinearProgress
        sx={{
          height: 7,
          borderRadius: 20,
          backgroundColor: '#e0e0e0',
          '& .MuiLinearProgress-bar': {
            background: success
              ? '#27AE60'
              : 'linear-gradient(to right, #EB5757, #FF9536)',
          },
        }}
        variant="determinate"
        value={percentage - 1}
        style={{flexGrow: 1}}
      />
      <Box
        sx={{
          height: 16,
          width: 16,
          borderRadius: 20,
          backgroundColor: success ? '#27AE60' : '#F2994A',
        }}
        style={{transition: 'left 0.4s linear'}}
        position="absolute"
        left={`${value - 1}%`}
        display="flex"
        alignItems="center"
        justifyContent="center"
        width={0}></Box>
    </Box>
  );
};

export default CustomLinearProgress;
